import { ReactElement } from 'react';

import { GetStaticProps } from 'next';

import { LoginPage, LoginPageLayout } from '@features/login';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      messages: {
        ...require(`../../public/locales/${locale}.json`)
      }
    }
  };
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <LoginPageLayout>{page}</LoginPageLayout>;
};

export default LoginPage;
